<template>
  <!-- cart START -->
  <div class="cart">

    <!-- cart:empty START -->
    <div v-if="storeCart.items && storeCart.items.length === 0">
      <h1 v-html="$t('cart.cartempty')"></h1>
    </div>
    <!-- cart:empty END -->

    <!-- EAN:product START -->
    <div
      class="ean-product"
      v-if="eanActive"
    >
      <el-input type="text" v-model="productEAN" :placeholder="$t('input.product.ean')" />
    </div>
    <!-- EAN:product END -->

    <!-- cart:products START -->
    <transition-group
      name="products-group"
      tag="div"
      :class="[
        'products',
        { 'no-products' : storeCart.items && storeCart.items.length === 0 },
        { 'with-ean-input' : eanActive },
      ]"
    >
      <!-- cart:products:product START -->
      <product
        v-for="product in cart"
        :key="product.id"
        :product="product"
        :increase="increase"
        :decrease="decrease"
      />
      <!-- cart:products:product END -->
    </transition-group>
    <!-- cart:products END -->

    <!-- cart:actions START -->
    <actions
      :products="storeCart"
      :total="total"
      :checkoutCart="checkoutCart"
      :eanActive="eanActive"
      :productEAN="!eanActive ? null : productEAN"
      :askUser="!eanActive ? askUser : addProduct"
    />
    <!-- cart:actions END -->

  </div>
  <!-- cart END -->
</template>

<script>
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'cart',
  components: {
    Actions: () => import('../components/cart/Actions.vue'),
    Product: () => import('../components/cart/Product.vue'),
  },
  data() {
    return {
      productEAN: null,
    };
  },
  computed: {
    ...mapGetters({
      storeCart: 'storeCart',
    }),

    cart() {
      if (this.$store.getters.storeCart) {
        return this.$store.getters.storeCart.items;
      }
      return this.$store.state.storeCart;
    },

    total() {
      if (this.$store.getters.storeCart) {
        return this.$store.getters.storeCart.sum;
      }
      return 0;
    },

    eanActive() {
      if (typeof this.$store.getters.eanActive !== 'undefined') {
        return this.$store.getters.eanActive;
      }
      return false;
    },
  },
  methods: {
    ...mapActions([
      'addItem',
      'removeItem',
      'updateItem',
    ]),

    async addProduct(EAN) {
      if (EAN) {
        const addedItem = await this.addItem(EAN);
        if (addedItem) {
          this.productEAN = null;
          this.success();
          setTimeout(() => {
            this.scrollTo('.products');
          }, 350);
        } else {
          this.error();
        }
      }
    },

    removeProduct(id) {
      if (id) {
        this.removeItem(id);
      }
    },

    increase(product) {
      if (product) {
        const p = product;
        p.quantity += 1;
        this.updateItem({
          id: Number(p.id),
          quantity: p.quantity,
        });
      }
    },

    decrease(product) {
      if (product) {
        if (product.quantity > 1) {
          const p = product;
          p.quantity -= 1;
          this.updateItem({
            id: Number(p.id),
            quantity: p.quantity,
          });
        } else {
          this.removeItem(product.id);
        }
      }
    },

    scrollTo(target) {
      const container = this.$el.querySelector(target);
      container.scrollTop = container.scrollHeight;
    },

    checkoutCart(co) {
      if (co) {
        this.$router.push('cart/purchase');
      }
    },

    prepare() {
      BarcodeScanner.prepare();
    },

    async startScan() {
      const status = await BarcodeScanner.checkPermission({ force: true });

      // check premissions - camera scan
      if (!status.granted) {
        // the user denied permission for good
        // redirect user to app settings if they want to grant it anyway
        // eslint-disable-next-line
        const c = confirm(this.$t('camera.grant.message'));
        if (c) {
          BarcodeScanner.openAppSettings();
        }
      } else {
        document.getElementById('app').style.opacity = 0;
        document.getElementById('app').style.background = 0;
        document.getElementById('scanner-ui').style.opacity = 1;
        document.getElementById('scanner-ui').style.visibility = 'visible';
        document.body.style.background = 'transparent';
        BarcodeScanner.hideBackground();
        // document.body.classList.add('qrscanner');
        const result = await BarcodeScanner.startScan();
        // document.body.classList.remove('qrscanner');
        if (result.hasContent) {
          document.getElementById('app').style.opacity = 1;
          document.getElementById('app').style.background = '';
          document.getElementById('scanner-ui').style.opacity = 0;
          document.getElementById('scanner-ui').style.visibility = 'hidden';
          document.body.style.background = 'var(--primary-background-color)';
          this.addProduct(result.content);
        }
      }
    },

    stopScan() {
      BarcodeScanner.showBackground();
      BarcodeScanner.stopScan();

      document.body.style.background = '';
      document.body.style.opacity = '1';
    },

    askUser() {
      this.prepare();
      this.startScan();
    },

    success() {
      this.$notify({
        message: this.$t('cart.product.success'),
        type: 'success',
        position: 'bottom-right',
      });
    },

    error() {
      this.$notify({
        message: this.$t('cart.product.failed'),
        type: 'error',
        position: 'bottom-right',
      });
    },
  },
  mounted() {
    // dispatch store cart
    if (this.$store.getters.storeCart) {
      this.$store.dispatch('setCart');
    }

    // dispatch ean status
    if (typeof this.$store.getters.eanActive !== 'undefined') {
      this.$store.dispatch('setEan');
    }

    // close $notify from Payback Screen
    this.$notify.closeAll();
  },
};
</script>
